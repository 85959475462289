.loginModal{
    background-color: #1B1B1C;
    height: auto;
    width: 100%;

    > .SIGNIN{
        padding: 0px 20px;
        @media (min-width : 992px){
            padding: 0px 40px;
        }
    }

    > .SIGNUP{
        padding: 0px 20px;
        @media (min-width : 992px){
            padding: 0px 40px;
        }
    }
    
// transform: rotate(-180deg);
}