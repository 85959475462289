.NOTOFICATION {
    background: #343436;
    border: 2px solid #383A3B;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: scroll;
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 100px;
    left: 20%;


    @media (min-width : 996px) {
        width: 350px;
        height: 450px;
        overflow-y: scroll;
    }
}