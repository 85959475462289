.FreeTutorialsCard{
    width: 100%;
    min-height: 240px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    z-index: 0;
    position: relative;
     &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient( 180deg , #1B1C1D 57.81%, #282828 100%);
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 6px;
     }
    

    .FreeTutorialsCard-img{
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        border-radius: 6px 6px 0px 0px;
        height: 178px;
        object-fit: cover;
    }
    .FreeTutorialsCard-title{
        height: calc(240px - 178px);
        border-radius: 0px 0px 6px 6px;
    }
}