.FREETUTORIALS-VIDEO {
  .TopicsTaught {
    transition: ease-in-out 4s;
    .topic-section {
      height: 350px;
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: #212122;
        border-radius: 6px;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #656568;
        border-radius: 6px;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .topic-section-ul {
        height: 270px;
        overflow-y: auto;
        overflow-x: hidden;
        ul {
          width: 95%;
          li {
            height: 56px;
          }
        }
      }
    }
    .TopicsTaught-body-close {
      transition: ease-in-out 4s;
      transform: translateY(-100%);
    }
  }
  .Introduction-video {
    height: auto;
    min-height: 550px;
    width: 100%;
    position: relative;
    .AboutCourse{
      @media (max-width : 768px ) {
        position: relative;
        top: -100px;
      }
    }

    .TeacherImage {
      height: 500px;
      position: relative;
      &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #020202 0%, rgba(2, 2, 2, 0) 80%);

      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .AboutCourse {
      height: 500px;
    }
  }
}