.CommentCard {
    width: 100%;
    min-height: 300px;
    height: auto;

    .CommentCard-img {
        @media (max-width : 992px) {
            width: 90%;
        }


        img {
            width: 100%;
            height: auto;
            
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            

            @media (min-width : 992px) {
                border-radius: 6px;
                width: 300px;
                height: 420px;
                object-fit: cover;
            }
        }
    }

    .CommentCard-text {
        width: 90%;
        height: 300px;
        border-radius: 13px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;


        @media (min-width : 992px) {
            border-radius: 13px;
            width: 500px;
            height: 300px;
            position: relative;
            right: -40px;
        }

    }
}