.SelectInput {
  color: black;
  .DropDownMenu{
    min-height: 50px;
    max-height: 200px;
    overflow-y: auto;
  }
  // &:active {
  //   color: #ffffff; // for drop down menu color
  // }
  
}
