.blog-card {
  height: 300px;
  .img-box {
    border-radius: inherit;
    height: 175px;
  }
  .body {
    height: 125px;
  }
}
