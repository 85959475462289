.NewTopicCard {

    width: 98%;
    
    height: 240px;

    overflow: hidden;
    position: relative;
    z-index: 2;

    cursor: pointer;
    border: none;


    &::after{
        content: "";
        position: absolute;
        z-index: 2;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 20%;
        background: linear-gradient(0deg, #2A2B2D 0%, rgba(45, 46, 47, 0.8125) 26.56%, rgba(47, 48, 49, 0.639628) 56.25%, rgba(57, 57, 57, 0) 100%);
        border-radius: 6px;
    }


        .NewTopicCard-img{
            position: absolute;
            z-index: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all 1s ease;
            border-radius: 6px;
            vertical-align: middle;
            &:hover{
                transform: scale(1.05);
            }
        }


}