.BuyConsultation {
    width: 100%;
    min-height: 500px;
    position: relative;
    z-index: 1;

    

    .ConsultationForm {
        position: relative;
        z-index: 1;
        background-color: #232425; 
    }

}