.SearchBox {
    height: 40px;
    input {
      flex: 1;
      height: 100%;
      border-radius: inherit;
      outline: none;
      border: none;
      background-color: transparent;
    }
    button {
      width: 40px;
      height: 40px;
      border-radius: inherit;
      border: none;
      background-color: transparent;
    }
  }
  