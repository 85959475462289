.ASTROClass{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(29, 101, 202);
    .logoutButton{
        position: absolute;
        top: 0px;
        left: 15px;
        width: 35px;
        height: 35px;
        background-color: white;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;

    }
}