.Button {
  position: relative;
  overflow: hidden;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: -50%;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(255, 255, 255, 0.125);
  //   pointer-events: none;
  //   transform: skewX(25deg);
  // }
}
