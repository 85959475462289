.Profile {
    .profile-img {
      .bi-camera {
        width: 30px;
        height: 30px;
      }
      .profile-box {
        width: 100px;
        height: 100px;
        font-size: 4rem;
      }
    }
  }
  