.PRINT {
    width: 100%;
    min-height: auto;
    .PRINT-body {
        table {
            border-collapse: collapse;
            

            th,
            td {
                padding: 5px;
                border: 1px solid black;
            }
        }
    }

}