.MailingLabel{
        overflow-y: hidden !important;
        width: 100%;
        height: auto;
        font-size: 7px;
        page-break-after: avoid !important;

        table{
            border-collapse: collapse;
            th , td {
                border : 1px solid black;
                padding: 5px;
                font-size: 15px;
            }
        }
    }