.LoginModall {
    inset: 0;
    z-index: 10;
    .hide-btn {
      all: unset;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }
    .dialog {
      height: auto;
      border-radius: 20px;
      border: none;
    }
    &:not(.show) {
      pointer-events: none;
      background-color: transparent;
      .dialog {
        opacity: 0;
        transform: translateY(-100px);
      }
    }
    &.show {
      background-color: rgba($color: #000000, $alpha: 0.5);
      .dialog {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
      }
    }
  }
  