.Product {
  .img-box {
    // .circle {
    //   aspect-ratio: 1/1;
    // }
    .img-card {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }
  .tabs-btn button {
    height: 60px;
    &:first-child {
      border-top-right-radius: 0.5rem;
    }
    &:last-child {
      border-top-left-radius: 0.5rem;
    }
  }
}
