.Home {
  width: 100%;
  position: relative;

  overflow-x: hidden;

  // background: linear-gradient(180deg, #212122 0%, rgba(33, 33, 34, 0) 100%), linear-gradient(180deg, #212122 0%, rgba(33, 33, 34, 0) 100%);
  .main-section {
    min-height: 400px;
    width: 100%;

  }

  .AnimationCourse {
    width: 100%;
    position: relative;

    &::after {
      @media (min-width : 768px) {
        display: block;
      }

      display: none;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width:60%;
      z-index: 3;
      background: linear-gradient(270deg, #020202 26.66%, rgba(2, 2, 2, 0.951575) 39.74%, rgba(2, 2, 2, 0.850943) 51.17%, rgba(2, 2, 2, 0.541634) 69.53%, rgba(2, 2, 2, 0) 94.22%);

    }

    .AnimationCourse-header {
      @media (min-width : 768px) {
        position: absolute;
        right: 100px;
        top: 200px;
        z-index: 4;
        text-align: right;
      }

      position: relative;
      text-align: center;
    }

    .sample-slider .swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  .Services {
    position: relative;
    z-index: 5;

    .services-logo {
      position: absolute;
      right: -50px;
      z-index: 1;

      @media (min-width : 992px) {
        right: -100px;
      }
    }

    .Accordion {
      .accordion-button.collapsed::after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi45NzMyNCAwLjE5MzM2M0M3LjA4NjM5IDAuMDY5NDg4OSA3LjIzOTE5IDAgNy4zOTg0MyAwQzcuNTU3NjYgMCA3LjcxMDQ2IDAuMDY5NDg4OSA3LjgyMzYxIDAuMTkzMzYzQzcuODc5NDggMC4yNTQxOTEgNy45MjM4NCAwLjMyNjY5OCA3Ljk1NDEyIDAuNDA2NjYyQzcuOTg0NDEgMC40ODY2MjcgOCAwLjU3MjQ1MiA4IDAuNjU5MTQzQzggMC43NDU4MzQgNy45ODQ0MSAwLjgzMTY1OSA3Ljk1NDEyIDAuOTExNjIzQzcuOTIzODQgMC45OTE1ODggNy44Nzk0OCAxLjA2NDA5IDcuODIzNjEgMS4xMjQ5Mkw0LjQyNTYyIDQuODA2OTRDNC4zMTIyMSA0LjkzMDY0IDQuMTU5MzEgNSA0IDVDMy44NDA2OSA1IDMuNjg3NzkgNC45MzA2NCAzLjU3NDM4IDQuODA2OTRMMC4xNzYzODggMS4xMjQ5MkMwLjEyMDUyNCAxLjA2NDA5IDAuMDc2MTU5IDAuOTkxNTg4IDAuMDQ1ODc2OCAwLjkxMTYyM0MwLjAxNTU5NDcgMC44MzE2NTkgMCAwLjc0NTgzNCAwIDAuNjU5MTQzQzAgMC41NzI0NTIgMC4wMTU1OTQ3IDAuNDg2NjI3IDAuMDQ1ODc2OCAwLjQwNjY2MkMwLjA3NjE1OSAwLjMyNjY5OCAwLjEyMDUyNCAwLjI1NDE5MSAwLjE3NjM4OCAwLjE5MzM2M0MwLjI4OTU0IDAuMDY5NDg4OSAwLjQ0MjMzOSAwIDAuNjAxNTczIDBDMC43NjA4MDYgMCAwLjkxMzYwNSAwLjA2OTQ4ODkgMS4wMjY3NiAwLjE5MzM2M0w0LjAwMTMgMy4yMTNMNi45NzMyNCAwLjE5MzM2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
        background-position: center center;
        background-size: 8px 5px;
        color: white;
        background-color: #49494A;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi45NzMyNCAwLjE5MzM2M0M3LjA4NjM5IDAuMDY5NDg4OSA3LjIzOTE5IDAgNy4zOTg0MyAwQzcuNTU3NjYgMCA3LjcxMDQ2IDAuMDY5NDg4OSA3LjgyMzYxIDAuMTkzMzYzQzcuODc5NDggMC4yNTQxOTEgNy45MjM4NCAwLjMyNjY5OCA3Ljk1NDEyIDAuNDA2NjYyQzcuOTg0NDEgMC40ODY2MjcgOCAwLjU3MjQ1MiA4IDAuNjU5MTQzQzggMC43NDU4MzQgNy45ODQ0MSAwLjgzMTY1OSA3Ljk1NDEyIDAuOTExNjIzQzcuOTIzODQgMC45OTE1ODggNy44Nzk0OCAxLjA2NDA5IDcuODIzNjEgMS4xMjQ5Mkw0LjQyNTYyIDQuODA2OTRDNC4zMTIyMSA0LjkzMDY0IDQuMTU5MzEgNSA0IDVDMy44NDA2OSA1IDMuNjg3NzkgNC45MzA2NCAzLjU3NDM4IDQuODA2OTRMMC4xNzYzODggMS4xMjQ5MkMwLjEyMDUyNCAxLjA2NDA5IDAuMDc2MTU5IDAuOTkxNTg4IDAuMDQ1ODc2OCAwLjkxMTYyM0MwLjAxNTU5NDcgMC44MzE2NTkgMCAwLjc0NTgzNCAwIDAuNjU5MTQzQzAgMC41NzI0NTIgMC4wMTU1OTQ3IDAuNDg2NjI3IDAuMDQ1ODc2OCAwLjQwNjY2MkMwLjA3NjE1OSAwLjMyNjY5OCAwLjEyMDUyNCAwLjI1NDE5MSAwLjE3NjM4OCAwLjE5MzM2M0MwLjI4OTU0IDAuMDY5NDg4OSAwLjQ0MjMzOSAwIDAuNjAxNTczIDBDMC43NjA4MDYgMCAwLjkxMzYwNSAwLjA2OTQ4ODkgMS4wMjY3NiAwLjE5MzM2M0w0LjAwMTMgMy4yMTNMNi45NzMyNCAwLjE5MzM2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
        background-position: center center;
        background-size: 8px 5px;
        background-color: #49494A;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .AccordionBody {
        .AccordionTitle {
          background: linear-gradient(270.31deg, #2E51DB 0.24%, #39393A 99.73%);
        }

        background-image: url('../../../assets/icons/Home/Group32.png'),
        linear-gradient(270.31deg, #2E51DB 0.24%, #39393A 99.73%);
        background-repeat: no-repeat;
        background-size:100% 100%;

        // background-position:center center;
        // min-height: 100px;
        height: auto;
        box-shadow: 6px 12px 32px rgba(0, 0, 0, 0.12);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

  }

  .land-PopularClass {

    position: relative;
    top: 0px;

    .PopularClass-swiper {
      .swiper-pagination-bullet {
        background: rgb(113, 111, 111);
        width: 5px;
        height: 5px;
        border-radius: 5px;
        opacity: 1;
        position: relative;
        bottom: -20px;
      }

      .swiper-pagination-bullet-active {
        background-color: blue;

      }

      position: relative;
    }
  }

  // مباحث تدریس شده
  .TopicsTaught {
    background: #2E2E3038;
    border-radius: 20px;

    height: auto;
    width: 90%;

    @media (min-width : 992px) {
      width: 100%;
    }
  }

  // برترین لیدر ها
  .Land-BestLeader {
    .land-Table {
      width: 100%;
      overflow-x: auto;
      background: rgba(55, 55, 57, 0.36);

      table {
        width: 768px;
        height: 416px;
        overflow-x: auto;

        tr {
          width: 100%;
        }

        th,
        td {
          border-bottom: 1px solid rgba(194, 194, 194, 0.07);
          padding: 5px 8px;
        }

        th {
          color: #828282;
          text-align: center;

        }

        td {
          text-align: center;
          color: white;

          img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .course-efficiency {
    .value {
      width: 125px;

      div {
        height: 7px;

        &:last-child::before {
          content: "";
          position: absolute;
          background-color: var(--bs-primary);
          border-radius: inherit;
          top: 0;
          left: 0;
          height: 100%;
          width: var(--val);
        }
      }
    }
  }

  .Comments {
    .Comments-swiper {
      .swiper-pagination-bullet {
        background: rgb(113, 111, 111);
        width: 7px;
        height: 7px;
        border-radius: 5px;
        opacity: 1;
        position: relative;
        bottom: -20px;
      }

      .swiper-pagination-bullet-active {
        background-color: rgb(21, 24, 208);

      }

      position: relative;
    }
  }

  .PRODUCT {
    position: relative;


      .mySwiper {
        position: inherit;

        .swiper-pagination-bullets {
          position: absolute;
          bottom: -10px;

          .swiper-pagination-bullet {
            background: rgb(113, 111, 111);
            width: 7px;
            height: 7px;
            border-radius: 5px;
            opacity: 1;
            position: relative;
            bottom: 0px;
          }

          .swiper-pagination-bullet-active {
            background-color: rgb(21, 24, 208);

          }
        }
      }
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .SUBJECTS {
    margin: 50px 0px;

    .SubjectBtn {
      height: 40px;
      background: #2E51DB33;
      border-radius: 6px;
      color: #8F9FDD99;
      transition: all 200ms ease-in-out;
      border: 1px solid #2E51DB33;

      &:hover {
        border: 1px solid #2E51DB;
        color: #2E51DB;
      }
    }

    .Active {
      border: 1px solid #2E51DB;
      color: #2E51DB;
    }
  }
}