@font-face {
  font-family: "estedad-fa";
  src: url(../assets/fonts/FD/Estedad-FD-Regular.ttf);
}
@font-face {
  font-family: "estedad-en";
  src: url(../assets/fonts/Estedad-Regular.ttf);
}

@font-face {
  font-family: "iranSans";
  src: url(../assets/fonts//sans/IRANSansWebFaNum.ttf);
}

@font-face {
  font-family: "iranYekan";
  src: url(../assets/fonts/Yekan/IRANYekanXNoEn-Regular.ttf);
}

@font-face {
  font-family: "iranYekanNumber";
  src: url(../assets/fonts/Yekan/yekanNumber/IRANYekanXFaNum-Regular.ttf);
}