.Landing {
  position: relative;
  background-color: #000000;
  width: 100%;
  overflow-x: hidden;

  > header {
    
    @media (min-width: 992px) {
      position: relative;
      z-index: 5;
      width: 100%;
      // border-radius: 0 0 20px 20px;
      // box-shadow: 0px 4px 40px rgba(30, 56, 157, 0.04);
    }
    .user-control {
      @media (min-width : 992px) {
        > a.btn {
          width: 125px;
        }
      }
      
    }
  }
  
  > main {
    min-height: calc(100vh - 100px - 82px);
    height: auto;
    width: 100%;


  }
  > footer {
    
    height: auto;
    @media (min-width: 992px) {
      position: relative;
      border-radius: 44px 44px 0px 0px;
      background-color: #34343680;
    }
    a {
      color: var(--bs-light);
      &:hover {
        color: var(--bs-primary);
      }
    }
  }
}
