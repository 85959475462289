.ORDER {
    width: 100%;
    min-height: 100vh;

    .Order-body {
        width: 100%;
        height: auto;
        @media (min-width : 992px) {
            width: 360px;
            min-height: 460px;
        }
        @media (max-width : 650px){
            padding: 0px 30px;
        }
        border-radius: 16px;
        position: relative;

        .BuyBtn {
            border-radius: 16px;
            height: 62px;
            background-color: #2ED573;
            font-size: 20px;
            font-weight: 700;
        }

        .Order-img {
            position: relative;
            object-fit: cover;
            margin: auto;
            top: -60px;
            border-radius: 16px;
            width: 100%;
            height: 170px;

            @media (min-width : 992px) {
                width: 300px;
                height: 170px;
            }
        }

        .Order-slice {
            width: 100%;
            height: 50px;
            position: relative;
            z-index: 1;
            span {
                width: 100%;
                height: 5px;
                border-bottom: 2px dashed #647488;
                
            }

            &::after {
                content: "";
                position: absolute;
                left: -40px;
                top: 1px;
                width: 48px;
                height: 48px;
                background-color: black;
                border-radius: 50%;
                z-index: -1;
            }

            &::before {
                content: "";
                position: absolute;
                right: -40px;
                top: 1px;
                width: 48px;
                height: 48px;
                background-color: black;
                border-radius: 50%;
                z-index: -1;
            }
        }
    }
}