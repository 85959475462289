.AboutCourses{
    .QuestionFreeTutorial{
        .Accordion {
            .accordion.collapsed.body{
                background-color: red;
            }
            .accordion-button.collapsed::after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi45NzMyNCAwLjE5MzM2M0M3LjA4NjM5IDAuMDY5NDg4OSA3LjIzOTE5IDAgNy4zOTg0MyAwQzcuNTU3NjYgMCA3LjcxMDQ2IDAuMDY5NDg4OSA3LjgyMzYxIDAuMTkzMzYzQzcuODc5NDggMC4yNTQxOTEgNy45MjM4NCAwLjMyNjY5OCA3Ljk1NDEyIDAuNDA2NjYyQzcuOTg0NDEgMC40ODY2MjcgOCAwLjU3MjQ1MiA4IDAuNjU5MTQzQzggMC43NDU4MzQgNy45ODQ0MSAwLjgzMTY1OSA3Ljk1NDEyIDAuOTExNjIzQzcuOTIzODQgMC45OTE1ODggNy44Nzk0OCAxLjA2NDA5IDcuODIzNjEgMS4xMjQ5Mkw0LjQyNTYyIDQuODA2OTRDNC4zMTIyMSA0LjkzMDY0IDQuMTU5MzEgNSA0IDVDMy44NDA2OSA1IDMuNjg3NzkgNC45MzA2NCAzLjU3NDM4IDQuODA2OTRMMC4xNzYzODggMS4xMjQ5MkMwLjEyMDUyNCAxLjA2NDA5IDAuMDc2MTU5IDAuOTkxNTg4IDAuMDQ1ODc2OCAwLjkxMTYyM0MwLjAxNTU5NDcgMC44MzE2NTkgMCAwLjc0NTgzNCAwIDAuNjU5MTQzQzAgMC41NzI0NTIgMC4wMTU1OTQ3IDAuNDg2NjI3IDAuMDQ1ODc2OCAwLjQwNjY2MkMwLjA3NjE1OSAwLjMyNjY5OCAwLjEyMDUyNCAwLjI1NDE5MSAwLjE3NjM4OCAwLjE5MzM2M0MwLjI4OTU0IDAuMDY5NDg4OSAwLjQ0MjMzOSAwIDAuNjAxNTczIDBDMC43NjA4MDYgMCAwLjkxMzYwNSAwLjA2OTQ4ODkgMS4wMjY3NiAwLjE5MzM2M0w0LjAwMTMgMy4yMTNMNi45NzMyNCAwLjE5MzM2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
                background-position: center center;
                background-size: 8px 5px;
                background-color: #49494A;
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
              .accordion-button:not(.collapsed)::after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi45NzMyNCAwLjE5MzM2M0M3LjA4NjM5IDAuMDY5NDg4OSA3LjIzOTE5IDAgNy4zOTg0MyAwQzcuNTU3NjYgMCA3LjcxMDQ2IDAuMDY5NDg4OSA3LjgyMzYxIDAuMTkzMzYzQzcuODc5NDggMC4yNTQxOTEgNy45MjM4NCAwLjMyNjY5OCA3Ljk1NDEyIDAuNDA2NjYyQzcuOTg0NDEgMC40ODY2MjcgOCAwLjU3MjQ1MiA4IDAuNjU5MTQzQzggMC43NDU4MzQgNy45ODQ0MSAwLjgzMTY1OSA3Ljk1NDEyIDAuOTExNjIzQzcuOTIzODQgMC45OTE1ODggNy44Nzk0OCAxLjA2NDA5IDcuODIzNjEgMS4xMjQ5Mkw0LjQyNTYyIDQuODA2OTRDNC4zMTIyMSA0LjkzMDY0IDQuMTU5MzEgNSA0IDVDMy44NDA2OSA1IDMuNjg3NzkgNC45MzA2NCAzLjU3NDM4IDQuODA2OTRMMC4xNzYzODggMS4xMjQ5MkMwLjEyMDUyNCAxLjA2NDA5IDAuMDc2MTU5IDAuOTkxNTg4IDAuMDQ1ODc2OCAwLjkxMTYyM0MwLjAxNTU5NDcgMC44MzE2NTkgMCAwLjc0NTgzNCAwIDAuNjU5MTQzQzAgMC41NzI0NTIgMC4wMTU1OTQ3IDAuNDg2NjI3IDAuMDQ1ODc2OCAwLjQwNjY2MkMwLjA3NjE1OSAwLjMyNjY5OCAwLjEyMDUyNCAwLjI1NDE5MSAwLjE3NjM4OCAwLjE5MzM2M0MwLjI4OTU0IDAuMDY5NDg4OSAwLjQ0MjMzOSAwIDAuNjAxNTczIDBDMC43NjA4MDYgMCAwLjkxMzYwNSAwLjA2OTQ4ODkgMS4wMjY3NiAwLjE5MzM2M0w0LjAwMTMgMy4yMTNMNi45NzMyNCAwLjE5MzM2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
                background-position: center center;
                background-size: 8px 5px;
                background-color: #2E51DB  !important;
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }

            .AccordionBody {
              border: 1px solid #2E51DB;
              background-image: url('../../../../assets/icons/Home/Group32.png'),linear-gradient(270.31deg, #232425 0.24%, #232425 99.73%);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position:center center;
              height: auto;
              box-shadow: 6px 12px 32px rgba(0, 0, 0, 0.12);
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;

              .AccordionTitle{
                color: #2E51DB;
              }
            }
          }
    }
    .AboutCourses-sort{
        @media (min-width : 992px) {
            position: relative;
            top : -110px
        }
    }

    .QuestionFreeTutorial{
        .AccordionBody{
            background-image: url('../../../../assets/images/FreeTutorial/linear.png');
            background-repeat: no-repeat;
            background-size: 100% auto;
            
            
        }
    }
     .AboutTeacher{
        .AboutTeacher-picture{
            .book1{
                position: absolute;
                
                right : 5px;
                top: 150px;
                transform: rotatez(-20deg);
            }
            .book2{
                position: absolute;
                left : 50px;
                top: 30px;
            }


            .Pen1{
                position: absolute;
                right : 20%;
                top: 10px;
                
            }
            .Pen2{
                position: absolute;
                left : 120px;
                top: 15px;
                transform: rotatez(-20deg);
            }

            .Ruler1{
                position: absolute;
                right : 30%;
                top: 30px;
                transform: rotatez(-40deg);
            }
            .Ruler2{
                position: absolute;
                left : 180px;
                top: 40px;
            }

            .Color{
                position: absolute;
                left : 290px;
                top: 40px;
            }

            .Mess1{
                position: absolute;
                right : 1%;
                top: 70px;
            }
            .Mess2{
                position: absolute;
                right : 2%;
                top: 60%;
                transform : rotateZ(-60deg)
            }

            .aboutTeacherLaptop{
                width: 100%;
            }
        }
    }
}