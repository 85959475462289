@keyframes ping {
  75%,
  to {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes time {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes open {
  from {
    transition: transform 2s;
    transform: translateY(-10%)
    
  }
  to {
    transform: translateY(0)
  }
}

@keyframes close {
  from {
    transform: translateY(0)
  }
  to {
    transform: translateY(-10%)
  }
}



.animation-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.animation-time {
  animation: time 1s steps(1) infinite;
}
.animation-spin {
  animation: spin 2.5s linear infinite;
}

.animation-open{
  animation: open 1s linear forwards;
}

.animation-close{
  animation: close 1s linear forwards;
}
