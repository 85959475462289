.Accordion{
    width: 100%;
    .Accordion-close{
        width: 528px;
        height: 50px;
        .Accordion-btn{
            background: #39393A;
            border-radius: 10px;
            width: 100%;
            height: 50px;
            transition: ease-in-out 3000ms ;
            span{
                width: 24px;
                height: 24px;
                background: #49494A;

            }
        }
    }

    .Accordion-open{
        width: 528px;
        height: auto;
        background: linear-gradient(270.31deg, #2E51DB 0.24%, #39393A 99.73%) , url('../../../assets/icons/Home/Group32.png');
        box-shadow: 6px 12px 32px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        
    
        .Accordion-btn{
            background: transparent;
            border-radius: 10px;
            width: 528px;
            height: 50px;
            span{
                width: 24px;
                height: 24px;
                background: #49494A;
                transition: transform 1s;
                transform: rotate(180deg);
    
            }
        }
    }

}

