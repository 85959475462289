.PopularClassCard {
    position: relative;
    min-height: 420px;
    height: auto;
    width: 220px;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 2px;
    object-fit: cover;    
    background-color: transparent;
    z-index: 1;
    .PopularClassCard-img{
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 1s ease;
        border-radius: 6px;
        vertical-align: middle;
        &:hover{
            transform: scale(1.05);
        }
    }
    // &:hover {
    //     .popular-details {
            
    //         position: absolute;
    //         bottom: 50px;

    //         .popular-details-btn {
    //             transform: translateY(0%);
    //         }
    //     }
    // }
    .popular-object {
        width: 58px;
        height: 25px;
        border-radius: 12.5px;
    }
    .popular-details {
        width: 100%;
        position: absolute;
        bottom: 30px;
        // position: absolute;
        // transition: all 1000ms;
        // bottom: -90px;
        .popular-details-btn {
            // transform: translateY(200%);
            // transition: transform 1500ms;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 25%;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: -1;
        background: linear-gradient(0deg, rgba(2, 2, 2, 0.83) 0%, rgba(2, 2, 2, 0.7) 22.65%, rgba(2, 2, 2, 0.597712) 44.43%, rgba(2, 2, 2, 0) 94.22%);
    }
}