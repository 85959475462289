.LoginLayout {
  height: 100vh;
  .main-img,
  .routes {
    @media (min-width: 992px) {
      height: calc(100vh - 112px);
    }
  }
  .routes {
    .line-label {
      &::before {
        content: "";
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--bs-gray);
      }
      span {
        background-color: #f4f7fc;
      }
    }
    .sign-up-btn {
      width: 75px;
      height: 75px;
    }
  }
}
