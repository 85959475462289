.ToggleSwitch {
  .switch {
    width: 36px;
    height: 20px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    inset: 0;
  }

  .toggler-btn {
    height: 16px;
    width: 16px;
    bottom: 2px;
    left: 2px;
  }

  &.checked {
    .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    .toggler-btn {
      left: calc(100% - 18px);
    }
  }
}
