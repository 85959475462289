.EcoClassOnline {
    .h_iframe-aparat_embed_frame {
        position: relative;
        height: 80vh;
        width: 100%;
        
        &.ratio {
            display: block;
            width: 100%;
            height: 100%;
        }

        iframe {
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 0;
            width: 100%;
            height: 100%;
        }
    }
}