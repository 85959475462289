.AdminPanel {
  min-height: 100vh;

  .setting-btn {
    width: 40px;
    height: 40px;
    bottom: 10px;
    left: 10px;
  }

  .accordionAdmin {
    --bs-bg-opacity: 0;
    border-radius: 0 1rem 1rem 0;
    background-color: transparent;
    transition: background-color 250ms;

    .accordionAdmin-toggler {
      color: white;
      border-radius: inherit;
      background-color: transparent;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        pointer-events: none;
        display: none;

      }

      &::before {
        top: -30px;
        box-shadow: -15px 15px 0 0px white;
      }

      &::after {
        bottom: -30px;
        box-shadow: -15px -15px 0 0px white;
      }
    }

    .accordionAdmin-collapse {
      border-radius: inherit;
      height: 0;
      overflow: hidden;
      transition: 250ms linear;

      .accordionAdmin-body {
        padding: 0.5rem 1rem;

        div a i {
          opacity: 0;
          transition: 250ms;
          background-color: transparent;
          color: white;
        }

        div a.active i {
          opacity: 1;
        }
      }
    }

    &.active {
      --bs-bg-opacity: 0.25;

      .accordion-toggler {
        background-color: white;
        color: var(--bs-violet);

        &::before,
        &::after {
          display: block;
        }
      }
    }
  }
}

.sidebar {
  height: 100vh;

  .sidebar-content {
    width: 100%;
    height: 100%;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0.25rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #fff5;
      border-radius: 50rem;
    }

    >*:not(.bg-img) {
      position: relative;
    }

    a {
      padding: 0.5rem 0.75rem;
      border-right: 4px solid transparent;
      transition: 250ms;
      margin-bottom: 0.5rem;
      --bs-bg-opacity: 0;

      i {
        min-width: 30px;
        width: 30px;
        height: 30px;
        transition: inherit;
      }

      &:hover,
      &.active {
        --bs-bg-opacity: 0.1;
        border-right-color: var(--bs-primary);

        i {
          background-color: white;
          color: var(--bs-dark);
        }
      }

      &:hover,
      &.active {
        --bs-bg-opacity: 0.1;
        border-right-color: var(--bs-primary);

        i {
          background-color: white;
          color: var(--bs-dark);
        }
      }
    }
  }
}

>div>header {
  z-index: 5;

  .time {
    filter: blur(0.25px);

    span {
      &:nth-child(1) {
        color: var(--bs-primary);

        &::after {
          content: " :";
        }
      }

      &:nth-child(2) {
        color: var(--bs-danger);

        &::after {
          content: " :";
          animation: time 1s steps(1) infinite;
        }
      }

      &:nth-child(3) {
        color: var(--bs-warning);
      }

      &:nth-child(4) {
        color: var(--bs-dark-blue);
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .AdminPanel {
    .sidebar {
      position: fixed;
      pointer-events: none;
      background-color: transparent;
      z-index: 10;
      transition: background-color 250ms;

      .sidebar-content {
        width: 300px;
        transform: translateX(100%);
        transition: transform 250ms;

      }

      &.active {
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.25);

        .sidebar-content {
          transform: translateX(0);
        }
      }
    }
  }
}