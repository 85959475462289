.UsersPanel {
  background-color: #1B1C1D;
  @media (min-width : 992px) {
    background: #343436;
  }

  .sidebar-toggle,
  .sidebar {
    position: relative;
    z-index: 1;
    .logoutControl{
      position: relative;
      @media (min-width : 992px) {
        position: absolute;
        bottom: 0;
      }
    }

    &::after {
      content: "";
      background: linear-gradient(270deg, #1B1C1D 0%, #343436 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      border-radius: 40px 0px 0px 40px;
      display: none;

      @media (min-width : 992px) {
        display: block;
      }
    }

    // background: linear-gradient(270deg, #343436 0%, #1B1C1D 100%);
    @media (min-width:992px) {
      border-radius: 40px 0px 0px 40px;
    }

    a,
    button {

      transition: 250ms;

      i {
        width: 40px;
        height: 40px;
        background-color: white;
        color: var(--bs-primary);
        transition: 250ms;
      }

      span {
        color: white;
        transition: 250ms;
      }

      &.active {
        background: linear-gradient(270deg, #2E51DB 1.31%, rgba(46, 81, 219, 0) 93.79%);
        width: 150px;
        height: 40px;
        border-radius: 8px;
        box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);

        i {
          background-color: var(--bs-primary);
          color: white;
        }

        span {
          color: white;
        }
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: rgb(13, 13, 13);
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #343436;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1b1c1d;
  }

  .main-section {
    >header {

      padding-right: 0.75rem;
      padding-left: 0.75rem;

      .new-alert {
        width: 7.5px;
        height: 7.5px;
        top: 5px;
        right: 0px;
      }

      // .Notifications {
      .menu {
        width: 345px;
        max-width: 80vw;
        top: 100%;
        right: 0;
        z-index: 2;
        

        @media (min-width: 992px) {
          right: auto;
          left: 0;
        }

        border: 2px solid #d1d8f4;
        box-shadow: 0px 20px 30px rgba(46, 81, 219, 0.13);
        opacity: 0;
        transition: 250ms;
        pointer-events: none;
        transform: translateY(25px);

        >div {

          &::before,
          &::after {
            content: "";
            position: absolute;
            pointer-events: none;
            top: 10px;
            right: 0;
          }

          &::before {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 3px solid var(--variant);
            background-color: white;
            z-index: 1;
          }

          &::after {
            height: 100%;
            right: 4.5px;
            border-left: 1px dashed var(--bs-secondary);
          }

          &:last-child::after {
            display: none;
          }
        }
      }

      &:hover .menu {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
      }
    }

    // }
    .user-controls {
      width: 100%;
    }

    main {
      min-height: 100vh;
      background: #1b1c1d !important;

      @media (min-width : 992px) {
        border-radius: 0px 40px 40px 0px;
        height: 100vh;
        overflow-y: auto;
      }

    }
  }





  >footer {
    width: 100%;
    min-height: 212px;
    height: auto;

    .land-footer-img {
      width: 107px;
      height: 107px;
      border-radius: 5px;
    }

    .footer-divider {
      width: 110px;
      height: 0px;
      border: 1px solid rgba(255, 255, 255, 0.39);
      transform: rotate(-90deg);
    }

    @media (min-width: 992px) {
      position: relative;
      border-radius: 44px 44px 0px 0px;
    }

    a {
      color: var(--bs-light);

      &:hover {
        color: var(--bs-info);
      }
    }
  }

}