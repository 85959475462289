.TopicTaughtCard {
    width: 100%;
    min-height: 86px;
    height: auto;
    background: linear-gradient(270deg, rgba(33, 33, 34, 0.2) 11.26%, rgba(97, 97, 103, 0.184) 100%);
backdrop-filter: blur(5px);
    border-radius: 10px;

    img{
        height: 74px;
        width: 83px;
        border-radius: 8px;
        object-fit: cover;

    }

    // @media (min-width : 992px){
    //     width: 100;
    // }
}