.SHOWPRICE {
    height: auto;
    min-height: 300px;
    border-radius: 24px;
    .CASH {
        height: 80px;
        width: 100%;
        position: relative;
        border-radius: 24px;
        padding: 10px 5px;
        transition: all 200ms ease-in-out;
        // &:hover{
        //     // background-color: #2e51db;
        //     border: none;
        // }
    }

    .Installments{
        height: 80px;
        width: 100%;
        position: relative;
        border-radius: 24px;
        padding: 10px 5px;
    }

}