.FreeTuTorilas-Category{
    overflow-x: hidden;
    position: relative;
    .FreeTuTorilas-Grouping{
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 250px;
        height: 200px;
    }

    .Filter-toggle {
        z-index: 15;
        overflow-x: hidden;
        pointer-events: none;
        
        transition: 250ms;
      
        position: absolute;
        border-radius: 15px;

        .hide-btn {
          all: unset;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: block;
        }

        .filterr {
         
          transform: translateY(150%);
          transition: 250ms;
          width: 100%;
        }
        &.active {
          pointer-events: all;
          background-color: rgba(0 ,0,0,0.9);
    
          top: 70px;
          left: 0px;
          right: 0;
          bottom: 0;
          .filterr {
            transform: translateY(0);
          }
        }
      }
    
    .NewTopicCard{
        width: 90%;
        height: auto;
        border-radius: 10px;
        z-index: 1;
        background-color: white;

        &::after{
            content: "";
            position: absolute;
            top: 80px;
            right: 20px;
            width: 52px;
            height: 52px;
            background: white;
            border-radius: 50%;
        }

        @media (min-width : 992px){
            width: 250px;
            height: 224px;
        }


        .NewTopicCard-image{
            width: 100%;
            height: 108px;
            border-radius: 10px;
            object-fit: cover;
            

        }

        .NewTopicCard-teacher{
            
            img{
            width: 52px;
            height: 52px;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid white;



            }
            position: absolute;
            top : 80px;
            right: 20px;
            z-index: 1;
        }
    }
}