.FullCalendar {
    position: relative !important;
    overflow: auto;
    width: 100%;

    .fc {
        overflow: hidden;
        z-index: 0 !important;
        background-color: transparent;
        min-height: 600px;
        position: relative !important;

        .fc-scrollgrid-liquid {
            border-radius: 8px 8px 0px 0px;
        }

        // table {
        //     thead {
        //         border-radius: 8px 8px 0px 0px;

        //         tr {
        //             th {
        //                 &:nth-child(7) {
        //                     border-radius: 8px 0px 0px 0px;
        //                 }

        //             }
        //         }
        //     }
        // }

        .fc-daygrid-day-frame {
            background-color: #1b1c1d;
            width: auto;

            .fc-daygrid-day-events {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .fc-daygrid-event-harness {
            width: 90%;
            margin: 5px 0px;

            @media (min-width : 1200px) {
                width: 150px;
                min-height: 66px;

            }



            // border-radius: 8px;
            .renderEventContent {
                font-size: 8px;

                @media (min-width : 992px) {
                    padding: 20px 10px;
                    font-size: 12px;
                }
            }

            .bg-green {
                border: 2px solid #1EA32B;
                border-radius: 8px !important;

                background-color: rgba(51, 110, 3, 0.4);

                .badge-calendar {
                    background-color: #1EA32B;
                    width: 100%;
                    font-size: 8px;

                    @media (min-width : 992px) {
                        width: 60px;
                        font-size: 12px;

                    }
                }
            }

            .bg-purple {
                border: 2px solid #8833FF;
                border-radius: 8px !important;

                background-color: rgba(136, 51, 255, 0.4);

                .badge-calendar {
                    background-color: #8833FF;
                    width: 100%;
                    font-size: 8px;

                    @media (min-width : 992px) {
                        width: 60px;
                        font-size: 12px;

                    }
                }
            }


            .bg-orange {
                border: 2px solid #FF6633;
                border-radius: 8px !important;

                background-color: rgba(255, 102, 51, 0.4);

                .badge-calendar {
                    background-color: #FF6633;
                    width: 100%;
                    font-size: 8px;

                    @media (min-width : 992px) {
                        width: 60px;
                        font-size: 12px;

                    }
                }
            }

            .bg-blue {
                border: 2px solid #2e51db;
                border-radius: 8px !important;

                background-color: rgba(46, 81, 219, 0.4);

                .badge-calendar {
                    background-color: #2e51db;
                    width: 100%;
                    font-size: 8px;

                    @media (min-width : 992px) {
                        width: 60px;
                        font-size: 12px;

                    }
                }
            }

            .bg-red {
                border: 2px solid #e6332a;
                border-radius: 8px !important;

                background-color: rgba(230, 51, 42, 0.4);

                .badge-calendar {
                    background-color: #e6332a;
                    width: 100%;
                    font-size: 8px;

                    @media (min-width : 992px) {
                        width: 60px;
                        font-size: 12px;

                    }
                }
            }

            opacity: 0.9;

            .fc-daygrid-event-dot {
                display: none;
            }
        }

        .fc-col-header {
            border-radius: 8px 8px 0px 0px;
            background: #262728;
            height: 33px;


        }

        // .fc-scrollgrid-section-header {
        //     border-radius: 0px 8px 0px 0px;
        //     background: #262728;
        //     height: 33px;
        // }

        .fc-header-toolbar {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;

            @media (min-width : 992px) {
                justify-content: space-between;

            }

            .fc-toolbar-title {
                color: white;
            }

            .fc-next-button {
                width: 33px;
                height: 33px;
                background-color: transparent;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 5px;
            }

            .fc-prev-button {
                width: 33px;
                height: 33px;
                background-color: transparent;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 5px;
            }
        }



        .fc-col-header-cell-cushion {
            color: white;
            font-weight: 300;
            font-size: 12px;

        }

        .fc-daygrid-day-number {
            color: white;
        }
    }
}