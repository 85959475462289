.SupportBtn {
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    position: fixed;
    z-index: 10;
    bottom: 10px;
    left: 15px;
    transition: all 300ms ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}