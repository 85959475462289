.SPECIAL-CLASS {
    width: 100%;
    height: 514px;
    position: relative;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient(0deg, rgba(2, 2, 2, 0.83) 0%, rgba(2, 2, 2, 0.7) 22.65%, rgba(2, 2, 2, 0.597712) 44.43%, rgba(2, 2, 2, 0) 94.22%);
    }

    .special-body {
        position: relative;
        @media (min-width : 992px) {
            left: 100px;
        }
        .special-category {
            width: 63px;
            height: 26px;
            background: #FFFFFF33;
            border-radius: 40px;
        }
    }
}