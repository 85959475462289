.ProductCard {
  background: rgba(71, 71, 71, 0.22);
  border-radius: 13px;
  height: 400px;
  width: 100%;
  
  &:hover {
    box-shadow: 0 0.5rem 1rem rgb(var(--bs-primary-rgb), 0.15);
  }
  .img-box {
    // aspect-ratio: 3/2;
    width: 100%;
    height: 300px;
    img{
      width: 100%;
      height: 100%;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
    }
  }
  .ProductCard-body{
    .land-books{
      background: rgba(46, 81, 219, 0.15);
      border-radius: 20px;
      width: 50px;
      height: 26px;
      color: #2E51DB;
  
    }
    .categories > *:not(:last-child) {
      margin-left: 0.5rem;
    }
    z-index: 0;
    position: relative;
     &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient( 180deg , #1B1C1D 57.81%, #282828 100%);
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 6px;
     }
  }
  
}
